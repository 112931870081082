$w-original-1: #006ed2;
$w-original-1-1: #005aac;
$w-original-2: #282828;
$w-original-3: #f6f6f6;
$w-original-4: #7f7f7f;

.file-ico {
  padding:.1rem;
  background:$w-original-3;
  display:block;
}
.file-text {
  font-size:.75rem;
  color:$gray-600;
  margin:.5rem 0 0;
  text-align:center;
  @include media-breakpoint-up(sm) {
      text-align:right;
    }
}
// btn
.btn {
  &-small {
    width:80%;
    margin-top: 1.5rem;
    padding: 0.5rem;
    display:block;
    box-sizing: border-box;
    @include media-breakpoint-up(sm) {
      width:220px;
      padding: 0.65rem;
      margin-top: 2rem;
    }
  }
  &-mid {
    width:80%;
    margin-top: 3rem;
    padding: 0.5rem;
    display:block;
    box-sizing: border-box;
    @include media-breakpoint-up(sm) {
      width:320px;
      padding: 0.95rem;
      margin-top: 5rem;
    }
  }
  &-col {
    text-align:center;
    justify-content: center;
    @include media-breakpoint-down(sm) {
      &--1,
      &--2 {
        margin-left:auto;
        margin-right:auto;
      }
      &--2 {
        margin-top:.5rem;
      }
    }
  }
  &-c {
    margin-left:auto;
    margin-right:auto;
  }
  &-l {
    margin-left:auto;
    margin-right:auto;
    @include media-breakpoint-up(sm) {
      margin-left:0;
      margin-right:auto;
    }
  }
  &-r {
    margin-left:auto;
    margin-right:auto;
    @include media-breakpoint-up(sm) {
      margin-left:auto;
      margin-right:0;
    }
  }
}

.header-logo {
  max-width:100px;
  margin:0 auto;
  @include media-breakpoint-up(sm) {
    max-width:120px;
  }
}

// form
.form-col {
  padding:2rem 0;
  border-bottom:1px solid $gray-300;
  @include media-breakpoint-down(sm) {
    padding:1rem 0;
    align-items: center;
  }
  &:first-of-type {
    border-top:1px solid $gray-300;
  }
  .col-form-label {
     font-weight:$font-weight-bold;
  }
}


textarea.form-control{
  padding: 0.7rem;
  &--150 {
    height:150px;
  }
}
.row-center {
  justify-content: center;
  @include media-breakpoint-down(sm) {
    .col-sm-3 + .col-sm-3 {
      margin-top:.75rem;
    }
  }
}
// member
.member {
  margin-top: 2rem;
  //overflow: hidden;
  &-heading {
    font-size:1.7rem;
    @include media-breakpoint-up(lg) {
      font-size:2rem;
    }
    &:after {
      content:"";
      width:80px;
      height:1px;
      margin:2rem auto;
      background:$w-original-1;
      display:block;
    }
  }
  &-btn {
    max-width:350px;
    margin:0 auto;
    a {
      font-size:1rem;
      padding:1rem;
      display:block;
    }
    input {
      width:100%;
      font-size:1rem;
      padding:1rem;
    }
  }
  .wizard-info.sw-theme-default > .nav .nav-link.active {
    color: $w-original-1!important;
  }
  .wizard-info.sw-theme-default > .nav .nav-link.active:after {
    background: $w-original-1!important;
  }
  .tab-pane {
    padding:2.2rem;
  }

  .row-1 {
      @include media-breakpoint-down(md) {
        display:block;
        &__col {
          max-width:100%;
          & + div {
            margin-top:1.4rem;
          }
        }
      }
  }

}
//reset password
.reset-pass-wrap {
  padding:.5rem;
  @include media-breakpoint-up(md) {
    padding:1rem;
  }
}
.reset-pass-heading {
  font-size:1.3rem;
  color:$white;
  padding:.1rem;
  background:$w-original-1;
  @include media-breakpoint-up(md) {
    padding:1rem;
  }
}
.reset-pass-text {
  line-height:1.8;
  @include media-breakpoint-up(md) {
    a:hover {
      text-decoration: underline;
    }
  }
}

.plan {
  .col-sm-10 {
    .form-check:first-of-type {
      @extend .mb-2
    }
  }
}

.alert-outline.alert-alert .alert-icon, .alert-outline-coloured.alert-danger .alert-icon {
  background-color: #dc3545;
}

.alert-alert {
  border-color: #f5c6cb;
}

.alert-outline.alert-notice .alert-icon, .alert-outline-coloured.alert-danger .alert-icon {
  background-color: #28a745;
}

.alert-notice {
  border-color: #c3e6cb;
}
