@import "../spark/css/1-variables/light";
@import "../spark/css/1-variables/light";
@import "../spark/css/1-variables/app";
@import "./regulations/all";

label.required:after, legend.required:after {
  content: ' *';
  color: $red !important;
}

.wrapper {
  max-width: 1320px;
}

.sidebar {
  position: fixed !important;
  top: 0;
  height: 100vh;
}

button i.fa {
  width: 15px;
}

@media (min-width: 992px) {
  .wrapper {
    .main {
      margin-left: $sidebar-width;
    }

    .toggled + .main {
      margin-left: 0;
    }
  }
}

@media (max-width: 992px) {
  .wrapper {
    .main {
      margin-left: 0;
    }

    .toggled + .main {
      margin-left: $sidebar-width;
    }
  }
}

.card-title{
  background: #ff00ff;
}

.sort.asc:after {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #fff;
  content: "";
  position: relative;
  top: 13px;
  right: -5px;
  color: #fff
}
.sort.desc:after {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #fff;
  content: "";
  position: relative;
  top: -10px;
  right: -5px;
  color: #fff
}
