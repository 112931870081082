/*
    Common
*/

.wizard,
.tabcontrol
{
    display: block;
    width: 100%;
    overflow: hidden;
}

.wizard a,
.tabcontrol a
{
    outline: 0;
}

.wizard ul,
.tabcontrol ul
{
    list-style: none !important;
    padding: 0;
    margin: 0;
}

.wizard ul > li,
.tabcontrol ul > li
{
    display: block;
    padding: 0;
}

/* Accessibility */
.wizard > .steps .current-info,
.tabcontrol > .steps .current-info
{
    position: absolute;
    left: -999em;
}

.wizard > .content > .title,
.tabcontrol > .content > .title
{
    position: absolute;
    left: -999em;
}



/*
    Wizard
*/

.wizard > .steps
{
    position: relative;
    display: block;
    width: 100%;
}

.wizard.vertical > .steps
{
    display: inline;
    float: left;
    width: 30%;
}

.wizard > .steps .number
{
    font-size: 1.429em;
}

.wizard > .steps > ul > li
{
    width: 25%;
}

.wizard > .steps > ul > li,
.wizard > .actions > ul > li
{
    float: left;
}

.wizard.vertical > .steps > ul > li
{
    float: none;
    width: 100%;
}

.wizard > .steps a,
.wizard > .steps a:hover,
.wizard > .steps a:active
{
    display: block;
    width: auto;
    margin: 0 0.5em 0.5em;
    padding: 1em 1em;
    text-decoration: none;

    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.wizard > .steps .disabled a,
.wizard > .steps .disabled a:hover,
.wizard > .steps .disabled a:active
{
    background: #eee;
    color: #aaa;
    cursor: default;
}

.wizard > .steps .current a,
.wizard > .steps .current a:hover,
.wizard > .steps .current a:active
{
    background: #2962FF;
    color: #fff;
    cursor: default;
}

.wizard > .steps .done a,
.wizard > .steps .done a:hover,
.wizard > .steps .done a:active
{
    background: #9dc8e2;
    color: #fff;
}

.wizard > .steps .error a,
.wizard > .steps .error a:hover,
.wizard > .steps .error a:active
{
    background: #ff3111;
    color: #fff;
}

.wizard > .content
{
    background: #eee;
    display: block;
    margin: 0.5em;
    overflow: hidden;
    position: relative;
    width: auto;

    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.wizard.vertical > .content
{
    display: inline;
    float: left;
    margin: 0 2.5% 0.5em 2.5%;
    width: 65%;
}

.wizard > .content > .body
{
    float: left;

    width: 100%;
    height: 95%;
    padding: 2.5%;
}

.wizard > .content > .body ul
{
    list-style: disc !important;
}

.wizard > .content > .body ul > li
{
    display: list-item;
}

.wizard > .content > .body > iframe
{
    border: 0 none;
    width: 100%;
    height: 100%;
}

.wizard > .content > .body input
{
    display: block;

}

.wizard > .content > .body input[type="checkbox"]
{
    display: inline-block;
}

.wizard > .content > .body input.error
{
    background: rgb(251, 227, 228);
    border: 1px solid #fbc2c4;
    color: #8a1f11;
}

.wizard > .content > .body label
{
    display: inline-block;
    margin-bottom: 0.5em;
}

.wizard > .content > .body label.error
{
    color: #8a1f11;
    display: inline-block;
    margin-left: 1.5em;
}

.wizard > .actions
{
    position: relative;
    display: block;
    text-align: right;
    width: 100%;
}

.wizard.vertical > .actions
{
    display: inline;
    float: right;
    margin: 0 2.5%;
    width: 95%;
}

.wizard > .actions > ul
{
    display: inline-block;
    text-align: right;
}

.wizard > .actions > ul > li
{
    margin: 0 0.5em;
}

.wizard.vertical > .actions > ul > li
{
    margin: 0 0 0 1em;
}

.wizard > .actions a,
.wizard > .actions a:hover,
.wizard > .actions a:active
{
    background: #2962FF;
    color: #fff;
    display: block;
    padding: 0.5em 1em;
    text-decoration: none;

    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.wizard > .actions .disabled a,
.wizard > .actions .disabled a:hover,
.wizard > .actions .disabled a:active
{
    background: #eee;
    color: #aaa;
}

.wizard > .loading
{
}

.wizard > .loading .spinner
{
}



/*
    Tabcontrol
*/

.tabcontrol > .steps
{
    position: relative;
    display: block;
    width: 100%;
}

.tabcontrol > .steps > ul
{
    position: relative;
    margin: 6px 0 0 0;
    top: 1px;
    z-index: 1;
}

.tabcontrol > .steps > ul > li
{
    float: left;
    margin: 5px 2px 0 0;
    padding: 1px;

    -webkit-border-top-left-radius: 5px;
    -webkit-border-top-right-radius: 5px;
    -moz-border-radius-topleft: 5px;
    -moz-border-radius-topright: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.tabcontrol > .steps > ul > li:hover
{
    background: #edecec;
    border: 1px solid #bbb;
    padding: 0;
}

.tabcontrol > .steps > ul > li.current
{
    background: #fff;
    border: 1px solid #bbb;
    border-bottom: 0 none;
    padding: 0 0 1px 0;
    margin-top: 0;
}

.tabcontrol > .steps > ul > li > a
{
    color: #5f5f5f;
    display: inline-block;
    border: 0 none;
    margin: 0;
    padding: 10px 30px;
    text-decoration: none;
}

.tabcontrol > .steps > ul > li > a:hover
{
    text-decoration: none;
}

.tabcontrol > .steps > ul > li.current > a
{
    padding: 15px 30px 10px 30px;
}

.tabcontrol > .content
{
    position: relative;
    display: inline-block;
    width: 100%;
    height: 35em;
    overflow: hidden;
    border-top: 1px solid #bbb;
    padding-top: 20px;
}

.tabcontrol > .content > .body
{
    float: left;
    position: absolute;
    width: 95%;
    height: 95%;
    padding: 2.5%;
}

.tabcontrol > .content > .body ul
{
    list-style: disc !important;
}

.tabcontrol > .content > .body ul > li
{
    display: list-item;
}

/*
Template Name: Monster Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: css
*/

.wizard-content .wizard>.steps>ul>li:after,
.wizard-content .wizard>.steps>ul>li:before {
    content: '';
    z-index: 9;
    display: block;
    position: absolute
}

.wizard-content .wizard {
    width: 100%;
    overflow: hidden
}

.wizard-content .wizard .content {
    margin-left: 0!important
}

.wizard-content .wizard>.steps {
    position: relative;
    display: block;
    width: 100%
}

.wizard-content .wizard>.steps .current-info {
    position: absolute;
    left: -99999px
}
.wizard-content  .wizard > .steps .current a, .wizard-content .wizard > .actions a,.wizard-content .wizard > .actions a:hover,
.wizard-content .wizard > .steps .disabled a, .wizard-content .wizard > .steps .disabled a:hover, .wizard-content .wizard > .steps .disabled a:active,
.wizard-content .wizard > .content,.wizard-content .wizard > .steps .done a, .wizard-content .wizard > .steps .done a:hover, .wizard-content .wizard > .steps .done a:active{
    background: transparent;
}
.wizard-content .wizard > .steps > ul > li,.wizard-content .wizard > .actions > ul > li{
    float:none;
}
.wizard-content .wizard>.steps>ul {
    display: table;
    width: 100%;
    table-layout: fixed;
    margin: 0;
    padding: 0;
    list-style: none
}

.wizard-content .wizard>.steps>ul>li {
    display: table-cell;
    width: auto;
    vertical-align: top;
    text-align: center;
    position: relative
}

.wizard-content .wizard>.steps>ul>li {
  a,
  .step-number {
    position: relative;
    padding-top: 52px;
    margin-top: 20px;
    margin-bottom: 20px;
    display: block
  }
}

.wizard-content .wizard>.steps>ul>li:before {
    left: 0
}

.wizard-content .wizard>.steps>ul>li:after {
    right: 0
}

.wizard-content .wizard>.steps>ul>li:first-child:before,
.wizard-content .wizard>.steps>ul>li:last-child:after {
    content: none
}

.wizard-content .wizard>.steps>ul>li.current {
  &>a,
  &>.step-number {
    color: #2f3d4a;
    cursor: default
  }
}

.wizard-content .wizard>.steps>ul>li.current .step {
    border-color: #2962FF ;
    background-color: #fff;
    color: #2962FF
}

.wizard-content .wizard>.steps>ul>li.disabled a,
.wizard-content .wizard>.steps>ul>li.disabled a:focus,
.wizard-content .wizard>.steps>ul>li.disabled a:hover {
    color: #999;
    cursor: default
}

.wizard-content .wizard>.steps>ul>li.done a,
.wizard-content .wizard>.steps>ul>li.done a:focus,
.wizard-content .wizard>.steps>ul>li.done a:hover {
    color: #999
}

.wizard-content .wizard>.steps>ul>li.done .step {
    background-color: #2962FF ;
    border-color: #2962FF ;
    color: #fff
}

.wizard-content .wizard>.steps>ul>li.error .step {
    border-color: #f62d51;
    color: #f62d51
}

.wizard-content .wizard>.steps .step {
    background-color: #fff;
    display: inline-block;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -24px;
    z-index: 10;
    text-align: center
}

.wizard-content .wizard>.content {
    overflow: hidden;
    position: relative;
    width: auto;
    padding: 0;
    margin: 0
}

.wizard-content .wizard>.content>.title {
    position: absolute;
    left: -99999px
}

.wizard-content .wizard>.content>.body {
    padding: 0 20px
}

.wizard-content .wizard>.content>iframe {
    border: 0;
    width: 100%;
    height: 100%
}

.wizard-content .wizard>.actions {
    position: relative;
    display: block;
    text-align: right;
    padding: 0 20px 20px
}

.wizard-content .wizard>.actions>ul {
    float: right;
    list-style: none;
    padding: 0;
    margin: 0
}

.wizard-content .wizard>.actions>ul:after {
    content: '';
    display: table;
    clear: both
}

.wizard-content .wizard>.actions>ul>li {
    float: left
}

.wizard-content .wizard>.actions>ul>li+li {
    margin-left: 10px
}

.wizard-content .wizard>.actions>ul>li {
  &>a,
  &>.step-number {
    background: #2962FF ;
    color: #fff;
    display: block;
    padding: 7px 12px;
    border-radius: 4px;
    border: 1px solid transparent
  }
}

.wizard-content .wizard>.actions>ul>li>a:focus,
.wizard-content .wizard>.actions>ul>li>a:hover {
    -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, .05) inset;
    box-shadow: 0 0 0 100px rgba(0, 0, 0, .05) inset
}

.wizard-content .wizard>.actions>ul>li>a:active {
    -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, .1) inset;
    box-shadow: 0 0 0 100px rgba(0, 0, 0, .1) inset
}

.wizard-content .wizard>.actions>ul>li>a[href="#previous"] {
    background-color: #fff;
    color: #54667a;
    border: 1px solid #d9d9d9
}

.wizard-content .wizard>.actions>ul>li>a[href="#previous"]:focus,
.wizard-content .wizard>.actions>ul>li>a[href="#previous"]:hover {
    -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, .02) inset;
    box-shadow: 0 0 0 100px rgba(0, 0, 0, .02) inset
}

.wizard-content .wizard>.actions>ul>li>a[href="#previous"]:active {
    -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, .04) inset;
    box-shadow: 0 0 0 100px rgba(0, 0, 0, .04) inset
}

.wizard-content .wizard>.actions>ul>li.disabled>a,
.wizard-content .wizard>.actions>ul>li.disabled>a:focus,
.wizard-content .wizard>.actions>ul>li.disabled>a:hover {
    color: #999
}

.wizard-content .wizard>.actions>ul>li.disabled>a[href="#previous"],
.wizard-content .wizard>.actions>ul>li.disabled>a[href="#previous"]:focus,
.wizard-content .wizard>.actions>ul>li.disabled>a[href="#previous"]:hover {
    -webkit-box-shadow: none;
    box-shadow: none
}

.wizard-content .wizard.wizard-circle>.steps>ul>li:after,
.wizard-content .wizard.wizard-circle>.steps>ul>li:before {
    top: 45px;
    width: 50%;
    height: 3px;
    background-color: #2962FF
}

.wizard-content .wizard.wizard-circle>.steps>ul>li.current:after,
.wizard-content .wizard.wizard-circle>.steps>ul>li.current~li:after,
.wizard-content .wizard.wizard-circle>.steps>ul>li.current~li:before {
    background-color: #F3F3F3
}

.wizard-content .wizard.wizard-circle>.steps .step {
    width: 50px;
    height: 50px;
    line-height: 45px;
    border: 3px solid #F3F3F3;
    font-size: 1.3rem;
    border-radius: 50%
}

.wizard-content .wizard.wizard-notification>.steps>ul>li:after,
.wizard-content .wizard.wizard-notification>.steps>ul>li:before {
    top: 39px;
    width: 50%;
    height: 2px;
    background-color: #2962FF
}

.wizard-content .wizard.wizard-notification>.steps>ul>li.current .step {
    border: 2px solid #2962FF ;
    color: #2962FF ;
    line-height: 36px
}

.wizard-content .wizard.wizard-notification>.steps>ul>li.current .step:after,
.wizard-content .wizard.wizard-notification>.steps>ul>li.done .step:after {
    border-top-color: #2962FF
}

.wizard-content .wizard.wizard-notification>.steps>ul>li.current:after,
.wizard-content .wizard.wizard-notification>.steps>ul>li.current~li:after,
.wizard-content .wizard.wizard-notification>.steps>ul>li.current~li:before {
    background-color: #F3F3F3
}

.wizard-content .wizard.wizard-notification>.steps>ul>li.done .step {
    color: #FFF
}

.wizard-content .wizard.wizard-notification>.steps .step {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 1.3rem;
    border-radius: 15%;
    background-color: #F3F3F3
}

.wizard-content .wizard.wizard-notification>.steps .step:after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -8px;
    margin-bottom: -8px;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 8px solid #F3F3F3
}

.wizard-content .wizard.vertical>.steps {
    display: inline;
    float: left;
    width: 20%
}

.wizard-content .wizard.vertical>.steps>ul>li {
    display: block;
    width: 100%
}
.wizard-content .wizard.vertical.wizard>.content{
    min-width: 80%
}
.wizard-content .wizard.vertical>.steps>ul>li.current:after,
.wizard-content .wizard.vertical>.steps>ul>li.current:before,
.wizard-content .wizard.vertical>.steps>ul>li.current~li:after,
.wizard-content .wizard.vertical>.steps>ul>li.current~li:before,
.wizard-content .wizard.vertical>.steps>ul>li:after,
.wizard-content .wizard.vertical>.steps>ul>li:before {
    background-color: transparent
}

@media (max-width:768px) {
    .wizard-content .wizard>.steps>ul {
        margin-bottom: 20px
    }
    .wizard-content .wizard>.steps>ul>li {
        display: block;
        float: left;
        width: 50%
    }
    .wizard-content .wizard>.steps>ul>li {
      &>a,
      &>.step-number {
        margin-bottom: 0
      }
    }
    .wizard-content .wizard>.steps>ul>li:first-child:before {
        content: ''
    }
    .wizard-content .wizard>.steps>ul>li:last-child:after {
        content: '';
        background-color: #2962FF
    }
    .wizard-content .wizard.vertical>.steps {
        width: 15%
    }
}

@media (max-width:480px) {
    .wizard-content .wizard>.steps>ul>li {
        width: 100%
    }
    .wizard-content .wizard>.steps>ul>li.current:after {
        background-color: #2962FF
    }
     .wizard-content .wizard.vertical>.steps>ul>li {
        display: block;
        float: left;
        width: 50%
    }
    .wizard-content .wizard.vertical>.steps {
        width: 100%;
        float:none;
    }
}
