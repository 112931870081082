// %copyright%

.r-steps-section {
  & ~ & {
    margin-top: 3rem;
  }
}

.r-steps-section--title,
.r-steps-section--body {
  a {
    &.r-help:hover {
      color: darken($r-help-color, 10%);
    }
  }
}

.r-steps-section--title {
  margin-bottom: 1rem;

  .card-title {
    display: inline-block;
  }

  .r-help {
    font-size: 1em;
    padding: .2rem .75rem .1rem;
    top: -.3rem;
    right: -.5rem;
  }
}

.r-steps-section--body {
  .r-help {
    padding: .2rem .75rem;
  }

  .r-help-full {
    display: block;
    padding: .75rem 1.25rem;
  }
}

.r-steps-section--body--paragraph {
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
}

a {
  &.r-steps-control {
    display: inline-block;
    margin-left: 2rem;
    margin-bottom: 1rem;

    &,
    &:hover {
      color: inherit;
    }
  }
}

.r-office-regulation--holidays-texts {
  .array-input-text-field {
    margin-bottom: 8px;
  }
}

.office_regulation_labor_systems.disabled {
  &,
  h5,
  .form-check-input:disabled ~ .form-check-label {
    color: $r-office-regulation-labor-systems-disabled-color;
  }
}

.r-steps-labor-systems--details {
  > p {
    margin: 0;
    padding: 0 1.25rem;
  }

  > .form-row {
    margin: 0;
    padding: .5rem 1rem 0;
  }

  .r-help {
    text-align: left;
    padding-top: .25rem;
    padding-bottom: .25rem;
  }
}

.r-steps-navigator {
  margin-bottom: 3rem;
}

.r-steps-paid-vacation--row {
  .col {
    padding-top: $r-steps-paid-vacation-table-padding;
    padding-bottom: $r-steps-paid-vacation-table-padding;
  }

  .col-form-label {
    padding-top: calc(#{$r-steps-paid-vacation-table-padding} + .375rem + 1px);
    padding-bottom: calc(#{$r-steps-paid-vacation-table-padding} + .375rem + 1px);
  }
}

.r-special-paid-leave--remove-button {
  font-size: 1.25em;
  position: relative;
  left: 6px;
}

.cocoon {
  .add_fields,
  .remove_fields {
    display: inline-block;
  }
}

.cocoon-disable-color {
  color: $r-text-color;
}

.r-working-time .card {
  background-color: $r-working-time-card-bg;
  border: 1px solid $r-working-time-card-border;
}

.r-pre-allowance {
  white-space: pre-wrap;
  color: $r-pre-code-color;
  background-color: $r-pre-code-bg;
  border: $r-pre-code-border;
  padding: .5em 1em;
}

.r-text-sample {
  color: $r-text-sample-color;
}
