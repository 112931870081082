// %copyright%

$r-text-color: #3e5569 !default;
$r-bg: #eef5f9 !default;

$gray-600: #6c757d !default;
$r-text-sample-color: lighten($gray-600, 20%) !default;

$r-steps-container-height: 500px !default;

$r-help-color: #85621b !default;
$r-help-bg: #fbd01d !default;
$r-help-table-border: #ccc !default;
$r-help-table-header-color: #eee !default;
$r-help-table-header-bg: #444 !default;

$r-steps-paid-vacation-table-padding: .6rem !default;

$r-office-regulation-labor-systems-disabled-color: #bcc1c6;

$r-card-shadow-color-tb: rgba(0, 0, 0, .08) !default;
$r-card-shadow-color-rl: rgba(0, 0, 0, .1) !default;

$r-working-time-card-bg: #fcfaf6 !default;
$r-working-time-card-border: darken($r-working-time-card-bg, 15%) !default;

$r-pre-code-color: #000 !default;
$r-pre-code-bg: #f6f6f6 !default;
$r-pre-code-border: 1px solid #ddd !default;

$r-link-disabled-color: #bbb;
$r-link-submitted-color: #0c0;

$r-law-table-bg: #f4f4f4;
