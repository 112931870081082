.sidebar-c {
  box-shadow:none;
  .sidebar-brand {
    padding-bottom:0;
  }
  &-logo {
    max-width:140px;
    margin:0 auto;
    display:block;
  }
  .sidebar-item.active > .sidebar-link,
  .sidebar-item.active .sidebar-link:hover {
    color:$w-original-1;
  }
  .sidebar-link svg,
  .sidebar-link .fas,
  .sidebar-link .far,
  .sidebar-link .fal,
  .sidebar-link .fab,
  a.sidebar-link svg,
  a.sidebar-link .fas,
  a.sidebar-link .far,
  a.sidebar-link .fal,
  a.sidebar-link .fab {
    color:$w-original-2;
  }
  .sidebar-item.active > .sidebar-link svg,
  .sidebar-item.active > .sidebar-link .fas,
  .sidebar-item.active > .sidebar-link .far,
  .sidebar-item.active > .sidebar-link .fal,
  .sidebar-item.active > .sidebar-link .fab,
  .sidebar-item.active .sidebar-link:hover svg,
  .sidebar-item.active .sidebar-link:hover .fas,
  .sidebar-item.active .sidebar-link:hover .far,
  .sidebar-item.active .sidebar-link:hover .fal,
  .sidebar-item.active .sidebar-link:hover .fab {
    color:$w-original-1;
  }
  .sidebar-item.active > .sidebar-link, .sidebar-item.active .sidebar-link:hover {
    background: $w-original-3;
  }
  .sidebar-link, a.sidebar-link {
    color:$w-original-2;
    &:hover {
      color:$w-original-4;
    }
  }

}
