$ionicons-font-path: '~ionicons/dist/fonts';

$font-family-sans-serif:      -apple-system, "BlinkMacSystemFont", "Helvetica Neue", Helvetica, "Arial", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;

$font-family-base:            $font-family-sans-serif !default;

$font-weight-lighter:         lighter !default;
$font-weight-light:           400 !default;
$font-weight-normal:          400 !default;
$font-weight-bold:            600 !default;
$font-weight-bolder:          bolder !default;

$headings-font-family:        $font-family-base !default;
$headings-font-weight:        $font-weight-bold !default;

$input-font-size:             .85rem;
$input-font-size-lg:          .85rem;

@import "../spark/css/1-variables/light";


// Spark

// Fonts
@import url('https://fonts.googleapis.com/css?family=Hind+Vadodara:400,500&display=swap');
@import url('https://indestructibletype.com/fonts/Jost.css');

// Theme variables
@import "../spark/css/1-variables/app";

// Bootstrap
@import "~bootstrap/scss/bootstrap";

// Theme mixins
@import "../spark/css/2-mixins/background";
@import "../spark/css/2-mixins/button";
@import "../spark/css/2-mixins/modal";
@import "../spark/css/2-mixins/placeholder";
@import "../spark/css/2-mixins/switch";
@import "../spark/css/2-mixins/tabs";
@import "../spark/css/2-mixins/wizard";

// Theme components
@import "../spark/css/3-components/accordion";
@import "../spark/css/3-components/alert";
@import "../spark/css/3-components/avatar";
@import "../spark/css/3-components/badge";
@import "../spark/css/3-components/breadcrumb";
@import "../spark/css/3-components/buttons";
@import "../spark/css/3-components/card";
@import "../spark/css/3-components/chart";
@import "../spark/css/3-components/chat";
@import "../spark/css/3-components/collapse";
@import "../spark/css/3-components/content";
@import "../spark/css/3-components/dropdown";
@import "../spark/css/3-components/feather";
@import "../spark/css/3-components/footer";
@import "../spark/css/3-components/forms";
@import "../spark/css/3-components/grid";
@import "../spark/css/3-components/hamburger";
@import "../spark/css/3-components/header";
@import "../spark/css/3-components/icon";
@import "../spark/css/3-components/landing";
@import "../spark/css/3-components/main";
@import "../spark/css/3-components/modal";
@import "../spark/css/3-components/nav";
@import "../spark/css/3-components/navbar";
@import "../spark/css/3-components/reboot";
@import "../spark/css/3-components/sidebar";
@import "../spark/css/3-components/splash";
@import "../spark/css/3-components/tables";
@import "../spark/css/3-components/tabs";
@import "../spark/css/3-components/type";
@import "../spark/css/3-components/wrapper";
@import "../spark/css/3-components/wizard";

// Theme utilities
@import "../spark/css/4-utilities/background";
@import "../spark/css/4-utilities/cursors";
@import "../spark/css/4-utilities/overflow";
@import "../spark/css/4-utilities/position";
@import "../spark/css/4-utilities/sizing";

// 3rd party plugins
// @import "~apexcharts/src/assets/apexcharts";
// @import "~bootstrap-daterangepicker/daterangepicker";
// @import "~bootstrap-markdown/css/bootstrap-markdown.min";
// @import "~datatables.net-bs4/css/dataTables.bootstrap4";
// @import "~datatables.net-buttons-bs4/css/buttons.bootstrap4";
// @import "~dragula/dist/dragula";
// @import "~fullcalendar/main";
@import "~ionicons/dist/scss/ionicons";
// @import "~jvectormap-next/jquery-jvectormap";
// @import "~quill/dist/quill.bubble";
// @import "~quill/dist/quill.snow";
@import "~select2/src/scss/core";
@import "~smartwizard/dist/css/smart_wizard_all";
@import "~tempusdominus-bootstrap-4/src/sass/tempusdominus-bootstrap-4";
// @import "~toastr/toastr";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";

// 3rd party plugin styles
// @import "../spark/css/5-vendor/datatables";
@import "../spark/css/5-vendor/datetimepicker";
// @import "../spark/css/5-vendor/fullcalendar";
@import "../spark/css/5-vendor/highlight";
@import "../spark/css/5-vendor/ion-icons";
// @import "../spark/css/5-vendor/markdown";
// @import "../spark/css/5-vendor/quill";
@import "../spark/css/5-vendor/select2";
@import "../spark/css/5-vendor/toastr";
@import "../spark/css/5-vendor/smartwizard";

// custom
@import "module/custom","module/override","module/sidebar";
