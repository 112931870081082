//オーバーライド
body {
 color:$w-original-2;
}

  .main {
      @include media-breakpoint-down(md) {
        //min-width: 100%;
      }
      //overflow:inherit;
  }

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    margin-bottom: 0.5rem;
    font-family: -apple-system, "BlinkMacSystemFont", "Helvetica Neue", Helvetica, "Arial", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 500;
    line-height: 1.6;
    color: $w-original-2;
}
hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    @include media-breakpoint-up(md) {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
}
a {
    color: $w-original-1;
    &:hover {
      color:$w-original-1-1;
      text-decoration: none;
    }
}
.bg-primary {
    background-color: $w-original-1;
}
.bg-light {
    background-color: $w-original-3 !important;
}
//ボタン
.btn {
  &-primary {
    background-color: $w-original-1;
    border-color: $w-original-1;
    &:hover {
      background-color: $w-original-1-1;
      border-color: $w-original-1-1;
    }
  }
}
.page-link {
    padding: 0.35rem 1rem;
    font-size: 1.125rem;
    line-height: 1.5;
    &:hover {
      color:$white;
      background :w-original-1;
    }
}

//タイトル
.header-title {
    font-size: 1.64062rem;
    font-weight:600;
    color:$w-original-1;
    margin-bottom:1.1rem;
}
// ぱんくず
.breadcrumb {
  font-size:.85rem;
  &-item + &-item::before {
    float: left;
    padding-right: 0.5rem;
    color: $gray-600;
    content: "|";
  }
  @include media-breakpoint-up(md) {
    a:hover {
      text-decoration: underline;
    }
  }
}
.card {
  box-shadow: none;
  &-header {
    padding:1.25rem 1.25rem 0;
    @include media-breakpoint-up(lg) {
      padding:2.5rem 2.5rem 0;
    }
    p {
      line-height:1.8;
      a:hover {
        text-decoration: underline;
      }
    }
  }
  &-body {
    padding:1.25rem;
    @include media-breakpoint-up(lg) {
      padding:2.5rem;
    }
    p {
      line-height:1.8;
      a:hover {
        text-decoration: underline;
      }
    }
  }
  &-title {
    font-size:1.3rem;
    padding:.4rem;
    background:$w-original-3;
  }
  &-link {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
.text-muted {
  font-size: .55rem;
  color:$gray-300;
  letter-spacing:.15rem;
  @include media-breakpoint-up(lg) {
    font-size: .75rem;
  }
}
.d-flex {
  .badge {
    padding: .3rem .5rem;
    @include media-breakpoint-down(md) {
      & + span {
        display:block;
      }
    }
  }
}
.c-btn {
  max-width:350px;
    margin:0 auto;
    a {
      padding:1rem;
      display:block;
    }
  }
.d-flex {
  &::last-of-type td {
    border-bottom: 1px solid $gray-300;
  }
  .badge {
    padding: .4rem .5rem;
  }
}
.chat-messages {
  overflow-y: auto;
}

.table th, .table td {
  border-top: none;
  border-bottom: 1px solid $gray-300;
}
