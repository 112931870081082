// %copyright%

.hide {
  display: none;
}

.r-help {
  display: inline-block;
  color: $r-help-color;
  background-color: $r-help-bg;
  border: 1px solid darken($r-help-bg, 20%);
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border-radius: 2px;
  text-align: center;
}

.r-help-container {
  text-align: left;

  .table {
    font-size: 90%;
  }

  img {
    width: 100%;
  }
}

.r-help-info {
  text-align: center;
}

.r-help-table {
  font-size: 90%;
  text-align: left;

  .row {
    border-top: 1px solid $r-help-table-border;
    border-right: 1px solid $r-help-table-border;
    border-left: 1px solid $r-help-table-border;

    &:last-child {
      border-bottom: 1px solid $r-help-table-border;
    }

    div:not(:first-child) {
      @media (min-width: 576px) {
        border-left: 1px solid $r-help-table-border;
      }
    }
  }
}

.r-help-table--header {
  color: $r-help-table-header-color;
  background-color: $r-help-table-header-bg;
  font-weight: 600;

  @media (min-width: 576px) {
    text-align: center;
  }
}

.r-help-label {
  font-weight: 600;
}

.r-help-xs-label {
  @media (max-width: 565px) {
    font-weight: 600;
  }
}


.swal2-popup .swal2-title {
  @media (max-width: 565px) {
    font-size: 1.4em;
  }
}

.r-help-block {
  @media (min-width: 768px) {
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    width: 70%;
  }
}
